import { useState } from 'react'
import { ArrowForwardRounded as ArrowRightRoundedIcon, Flag as FlagIcon } from '@mui/icons-material'
import { Box, styled, StyleProps, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/solid'

import {
  Badge,
  Body,
  Button,
  Cell,
  Chip,
  EmptyRow,
  Head,
  Pagination,
  Row,
  Skeleton,
  StickyCell,
  Table,
  TableContainer,
  Typography,
} from '@components'
import { useAppDispatch } from '@core/store'
import { colorScale } from '@core/styles/colors'

import { ReviewDrawer } from './review-drawer'
import { actions } from './review-slice'
import { ItemTypeSchema, PlainTextSchema, TagSchema, UnitSchema } from '@core/api/generated'
import { TagSwiper } from './components'
import { useListUnits } from '@core/api/hooks'

const CHIP_COLORS = colorScale(['purple', 'teal'])

enum NotificationType {
  NEW_EVIDENCE = 'NEW_EVIDENCE',
  NEW_COMMENT = 'NEW_COMMENT',
}

const styles: StyleProps = {
  title: {
    color: 'B300',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pagination: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 2,
    mb: 4,
  },
  wrapColumn: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}

const TitleCell = styled(Cell)`
  cursor: pointer;
`

const ReviewTableSkeleton = () => {
  return [...Array(10)].map((_, index) => (
    <Row key={index}>
      <Cell>
        <Box display="flex" gap={1}>
          <Skeleton width="30%" height={30} />
          <Skeleton width="30%" height={30} />
          <Skeleton width="30%" height={30} />
        </Box>
      </Cell>

      <Cell>
        <Skeleton width="100%" height={30} />
      </Cell>

      <Cell>
        <Skeleton width="100%" height={30} />
      </Cell>

      <Cell>
        <Skeleton width="100%" height={30} />
      </Cell>

      <Cell>
        <Skeleton width="100%" height={30} />
      </Cell>
    </Row>
  ))
}

const CommentCell = ({ unit }: { unit: UnitSchema }) => {
  const hasCommentNotification = unit.userNotifications?.some(
    (notification) => notification.type in NotificationType
  )
  const hasComments = unit.numberOfComments > 0

  return (
    <Cell>
      {hasCommentNotification && (
        <Badge variant="dot" color="info" pulse>
          <ChatBubbleOvalLeftEllipsisIcon height={24} width={24} />
        </Badge>
      )}

      {hasComments && !hasCommentNotification && (
        <ChatBubbleOvalLeftEllipsisIcon height={24} width={24} />
      )}
    </Cell>
  )
}

export default function ReviewTable() {
  const theme = useTheme()
  const isSmallerThanXL = useMediaQuery(theme.breakpoints.down('xl'))
  const dispatch = useAppDispatch()
  const history = useNavigate()

  const [recordsPerPage] = useState(10)
  const [searchParams, setSearchParams] = useSearchParams()

  const { projectId } = useParams() as { projectId: string }
  const { data, isLoading } = useListUnits({ projectId, searchParams })

  const numberOfPages = Math.ceil((data?.count ?? 0) / recordsPerPage)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [swiper, setSwiper] = useState<{
    index: number
    indexRow: number
    tags: TagSchema[]
  }>({
    index: 0,
    indexRow: 0,
    tags: [],
  })
  const [activeIndex, setActiveIndex] = useState<null | number>(0)

  const handleOpen = (id: string) => {
    dispatch(actions.set({ selectedUnit: id }))
  }

  const handleOpenPopup = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleCloseTagSwiper = () => {
    setAnchorEl(null)
    setActiveIndex(null)
  }

  const handlePage = (e: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: e.toString(),
    })
  }

  const handleTagClick = (
    e: React.MouseEvent<HTMLElement>,
    tags: TagSchema[],
    index: number,
    indexRow: number
  ) => {
    setActiveIndex(null)
    handleOpenPopup(e)
    setSwiper({
      index,
      indexRow,
      tags,
    })
  }

  const getContent = (content: PlainTextSchema | ItemTypeSchema) => {
    if ((content as PlainTextSchema)?.text !== undefined) {
      return (content as PlainTextSchema).text
    }
    return (content as ItemTypeSchema).stem
  }

  return (
    <>
      <ReviewDrawer />

      <TagSwiper
        tags={swiper?.tags}
        anchorEl={anchorEl}
        onClose={handleCloseTagSwiper}
        initialIndex={swiper.index}
        onSelectTag={(index) => setActiveIndex(index)}
      />

      <TableContainer>
        <Table aria-label="review" fixed>
          <Head>
            <Row>
              <StickyCell width={isSmallerThanXL ? '20%' : '15%'}>Tag</StickyCell>
              <Cell>Preview</Cell>
              <Cell width={80} aria-label="items with comments"></Cell>
              <Cell width={80} aria-label="flagged items"></Cell>
              <Cell width={210} aria-label="review detail items"></Cell>
            </Row>
          </Head>

          <Body hasData={!data}>
            {isLoading ? (
              <ReviewTableSkeleton />
            ) : (
              <>
                {data?.items.length === 0 ? (
                  <EmptyRow numberOfColumns={4}>
                    <Box sx={{ opacity: 0.5 }}>
                      <Typography>Nothing to see here.</Typography>
                    </Box>
                  </EmptyRow>
                ) : (
                  <>
                    {data?.items.map((row, indexRow) => (
                      <Row key={row.id}>
                        <StickyCell>
                          <Box sx={styles.wrapColumn} gap={1}>
                            <>
                              {row?.tags.length > 0 ? (
                                <>
                                  {row?.tags.map((tag, index) => {
                                    return (
                                      <Box key={index}>
                                        <Chip
                                          size="small"
                                          key={tag.framework.shortName}
                                          label={tag.framework.shortName}
                                          bgcolor={CHIP_COLORS[index] as string}
                                          onClick={(e) =>
                                            handleTagClick(e, row.tags as [], index, indexRow)
                                          }
                                          textColor="N000"
                                          focused={
                                            swiper.indexRow === indexRow && activeIndex === index
                                          }
                                          maxWidth={170}
                                        />
                                      </Box>
                                    )
                                  })}
                                </>
                              ) : (
                                <Chip size="small" key={row.id} label="No Tags" />
                              )}
                            </>
                          </Box>
                        </StickyCell>

                        <TitleCell onClick={() => handleOpen(row.id as string)}>
                          <Box sx={styles.title}>{getContent(row.content)}</Box>
                        </TitleCell>

                        <CommentCell unit={row} />

                        <Cell>
                          {row?.isFlagged && (
                            <FlagIcon
                              aria-label="flagged"
                              sx={{
                                color: 'R400',
                              }}
                            />
                          )}
                        </Cell>

                        <Cell>
                          <Button
                            fullWidth
                            onClick={() =>
                              history({
                                pathname: `../review/detail/${row?.id}`,
                                search: window.location.search,
                              })
                            }
                          >
                            Review item tags
                            <ArrowRightRoundedIcon sx={{ ml: 1.5 }} />
                          </Button>
                        </Cell>
                      </Row>
                    ))}
                  </>
                )}
              </>
            )}
          </Body>
        </Table>

        {numberOfPages > 1 && (
          <Box sx={styles.pagination}>
            <Pagination
              count={numberOfPages}
              page={parseInt(searchParams.get('page') as string) || 1}
              color="primary"
              onChange={(_, e) => handlePage(e)}
            />
          </Box>
        )}
      </TableContainer>
    </>
  )
}
